import React from 'react';
import { string } from 'prop-types';

const ICON_ID = 'recommendations-full_icon';

const IconFull = ({ className }) => (
  <svg className={className} width="41" height="13" viewBox="0 0 41 13" xmlns="http://www.w3.org/2000/svg" fill="none">
    <use href="#poly_full" />
  </svg>
);

IconFull.propTypes = {
  className: string,
};

IconFull.defaultProps = {
  className: null,
};

IconFull.ICON_ID = ICON_ID;

export default React.memo(IconFull);
export { IconFull };
